import {
    CONTACT_US_SUCCESS,
    CONTACT_US_FAILED,
    CONTACT_US_LOADING,
} from "../../actions/customers/types";

const initialState = {
    contactUsLoading: false,
    contactUsFailed: false,
};

export default function plans(state = initialState, action) {
    switch (action.type) {
        //
        case CONTACT_US_SUCCESS:
            return {
                ...state,
                contactUsLoading: false,
                contactUsFailed: false,
            };
        case CONTACT_US_FAILED:
            return {
                ...state,
                contactUsLoading: false,
                contactUsFailed: true,
            };
        case CONTACT_US_LOADING:
            return {
                ...state,
                contactUsLoading: true,
                contactUsFailed: false,
            };
        //

        default:
            return state;
    }
}
