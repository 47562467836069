export const UPDATE_QUOTE_REQUEST_SUCCESS = "UPDATE_QUOTE_REQUEST_SUCCESS";
export const UPDATE_QUOTE_REQUEST_FAILED = "UPDATE_QUOTE_REQUEST_FAILED";
export const UPDATE_QUOTE_REQUEST_LOADING = "UPDATE_QUOTE_REQUEST_LOADING";
//
export const UPDATE_QUOTE_REQUEST_STATUS_SUCCESS =
    "UPDATE_QUOTE_REQUEST_STATUS_SUCCESS";
export const UPDATE_QUOTE_REQUEST_STATUS_FAILED =
    "UPDATE_QUOTE_REQUEST_STATUS_FAILED";
export const UPDATE_QUOTE_REQUEST_STATUS_LOADING =
    "UPDATE_QUOTE_REQUEST_STATUS_LOADING";
//
