export const defaultKeyWords =
    "Kenya home designs,Architectural design,Architectural plan,House plan,Floor plan,Custom house plans,Build a house yourself,Bungalow,Maisonette,Mansion,Bedsitter,two bedroom house plan,three bedroom house plan,four bedroom house plan,Quantity surveyor,Hire a supervisor,Hire a contractor,County building approval,Cost of building a house in Kenya,Home Financing";
export const defaultTitle =
    "Browse House Plans, Save Up To 40% On Our Free Building App";
export const defaultDescription =
    "Our proven system offers modern designs with realistic cost projections (BQ), access to affordable suppliers near you and tools to help you build – Inuua.net. Browse our wide selection of 3 bedroom house plans, 2 and 4 bedroom, mansions, bungalows and more.";
export const defaultImages = [
    { url: "https://www.inuua.net/images/header-banner-large.jpg" },
    { url: "https://www.inuua.net/images/MaisonetteHomePage.jpg" },
];
