export const GET_MY_PLANS_SUCCESS = "GET_MY_PLANS_SUCCESS";
export const GET_MY_PLANS_FAILED = "GET_MY_PLANS_FAILED";
export const GET_MY_PLANS_LOADING = "GET_MY_PLANS_LOADING";
//
export const SHARE_PLAN_SUCCESS = "SHARE_PLAN_SUCCESS";
export const SHARE_PLAN_FAILED = "SHARE_PLAN_FAILED";
export const SHARE_PLAN_LOADING = "SHARE_PLAN_LOADING";
//
export const ADMIN_SHARE_PLAN_SUCCESS = "ADMIN_SHARE_PLAN_SUCCESS";
export const ADMIN_SHARE_PLAN_FAILED = "ADMIN_SHARE_PLAN_FAILED";
export const ADMIN_SHARE_PLAN_LOADING = "ADMIN_SHARE_PLAN_LOADING";
//
export const GET_SAVED_PLANS_SUCCESS = "GET_SAVED_PLANS_SUCCESS";
export const GET_SAVED_PLANS_FAILED = "GET_SAVED_PLANS_FAILED";
export const GET_SAVED_PLANS_LOADING = "GET_SAVED_PLANS_LOADING";
//
export const ADD_SAVED_PLAN_SUCCESS = "ADD_SAVED_PLAN_SUCCESS";
export const ADD_SAVED_PLAN_FAILED = "ADD_SAVED_PLAN_FAILED";
export const ADD_SAVED_PLAN_LOADING = "ADD_SAVED_PLAN_LOADING";
//
export const REMOVE_SAVED_PLAN_SUCCESS = "REMOVE_SAVED_PLAN_SUCCESS";
export const REMOVE_SAVED_PLAN_FAILED = "REMOVE_SAVED_PLAN_FAILED";
export const REMOVE_SAVED_PLAN_LOADING = "REMOVE_SAVED_PLAN_LOADING";
//
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLANS_FAILED = "GET_PLANS_FAILED";
export const GET_PLANS_LOADING = "GET_PLANS_LOADING";
//
