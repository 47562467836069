import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useAlert } from "react-alert";

const Alerts = (props) => {
    const alert = useAlert();

    // TODO: handle types
    useEffect(() => {
        if (props.alertMessage) {
            alert.show(
                props.alertTitle
                    ? props.alertTitle + ": " + props.alertMessage
                    : props.alertMessage,
                {
                    timeout: 2500, // custom timeout just for this one alert
                    type: props.alertType,
                    onOpen: () => {
                        // callback that will be executed after this alert open
                    },
                    onClose: () => {
                        // callback that will be executed after this alert is removed);
                    },
                }
            );
        }
    }, [props.alertTitle, props.alertMessage, props.alertTime]);

    //
    return <></>;
};

Alerts.propTypes = {
    alertTitle: PropTypes.string.isRequired,
    alertMessage: PropTypes.string.isRequired,
    alertTime: PropTypes.string.isRequired,
    alertType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    alertTitle: state.messagesReducer.title,
    alertMessage: state.messagesReducer.message,
    alertType: state.messagesReducer.type,
    alertTime: state.messagesReducer.time,
});

export default connect(mapStateToProps, {})(Alerts);
