import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkCachedAuth, loadUser } from "../../../state/actions/auth/auth";
// import Router from "next/router";

// to be added to hooks folder
function AutoAuth() {
    const dispatch = useDispatch();
    const authReducer = useSelector((state) => state.authReducer);

    useEffect(() => {
        if (!authReducer.isAuthenticated && !authReducer.isLoading) {
            dispatch(checkCachedAuth());
        }
    }, []);

    useEffect(() => {
        if (
            !!authReducer.isAuthenticated &&
            !authReducer.isLoading &&
            (!authReducer.user || !authReducer.user.id)
        ) {
            dispatch(loadUser());
        }
    }, [authReducer.isAuthenticated, authReducer.isLoading, authReducer.user]);

    return <></>;
}

export default AutoAuth;
