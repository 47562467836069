// Authentication
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const CHECK_CACHED_AUTH = "CHECK_CACHED_AUTH";
//
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_LOADING = "LOGIN_LOADING";
//
export const AUTH_ERROR = "AUTH_ERROR";
//
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT = "LOGOUT";
//
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";
export const REGISTER_LOADING = "REGISTER_LOADING";
//
export const REQUEST_RESET_PASSWORD_SUCCESS = "REQUEST_RESET_PASSWORD_SUCCESS";
export const REQUEST_RESET_PASSWORD_FAILED = "REQUEST_RESET_PASSWORD_FAILED";
export const REQUEST_RESET_PASSWORD_LOADING = "REQUEST_RESET_PASSWORD_LOADING";
//
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
