import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { useStore } from "../state/store";
import Router, { useRouter } from "next/router";
import NProgress from "nprogress";
import App from "../components/App";
import Script from "next/script";
//
import { init as initSentry } from "../lib/sentry";
import { GTMPageView } from "../lib/gtm";
import { googleAnalyticsPageView } from "../lib/ga";
// styles
import "../styles/index.css";
import "../styles/main.css";
import "nprogress/nprogress.css";

// sentry
initSentry();

// google tag manager config
// https://github.com/vercel/next.js/issues/4662
const handleRouteChange = (url) => GTMPageView(url);
const isBrowser = typeof window !== "undefined";
if (isBrowser) {
    // For the first page load
    handleRouteChange(window.location.href);
    // Subsequent route changes
    Router.onRouteChangeComplete = (url) =>
        handleRouteChange(window.location.href);
}

// Progress indicator
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

// const Alerts = dynamic(() => import("../components/templates/Alerts/Alerts"), {
//     ssr: false,
// });
// const AlertTemplate = dynamic(
//     () => import("../components/templates/Alerts/AlertTemplate"),
//     {
//         ssr: false,
//     }
// );

export default function MyApp({ Component, pageProps }) {
    const store = useStore(pageProps.initialReduxState);

    //
    // google analytics config
    const router = useRouter();
    useEffect(() => {
        const handleRouteChange = (url) => {
            googleAnalyticsPageView(url);
        };
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);
    //

    return (
        <>
            <Provider store={store}>
                <App>
                    <Component {...pageProps} />

                    {/* WhatsApp chat widget */}
                    <Script strategy="lazyOnload" id="GetButton.iowidget">
                        {`
                            <!-- GetButton.io widget -->
                                (function () {
                                    if (document.body.clientWidth < 767) {
                                        var options = {
                                            // facebook: "1912459639021901", // Facebook page ID
                                            whatsapp: "254701220004", // WhatsApp number
                                            call_to_action: "Talk to us", // Call to action
                                            button_color: "#FF6550", // Color of button
                                            position: "right", // Position may be 'right' or 'left'
                                            order: "facebook,whatsapp", // Order of buttons
                                        };
                                        var proto = document.location.protocol, host = "getbutton.io", url = proto + "//static." + host;
                                        var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = url + '/widget-send-button/js/init.js';
                                        s.onload = function () { WhWidgetSendButton.init(host, proto, options); };
                                        var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x);
                                    }
                                })();
                            <!-- /GetButton.io widget -->
                        `}
                    </Script>
                </App>
            </Provider>
        </>
    );
}
