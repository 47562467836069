import React from "react";
import Image from "next/image";
import {
    // BASE_URL,
    CDN_BASE_URL,
    CLOUDINARY_S3_PROD,
    CLOUDINARY_S3_STAGING,
    // CLOUDINARY_WEB_PROD,
    // CLOUDINARY_WEB_STAGING,
} from "@settings";

// function cloudImageLoader({ src, width, height, quality }) {
//     // https://docs.cloudimage.io/go/cloudimage-documentation-v7/en/introduction
//     const CLOUD_IMAGE_URL =
//         "https://auqqkwtyuq.cloudimg.io/" || process.env.CLOUD_IMAGE_URL;
//     return `${CLOUD_IMAGE_URL}${src}&width=${width}&q=${quality}&force_format=webp`;
// }

function getImagePath(url) {
    // return url.split("/").pop().split("#")[0].split("?")[0];
    var loc = new URL(url);
    return loc.pathname.slice(1);
}

function inuuaCDNLoader({ src, width = 0, height = 0, quality }) {
    // https://thumbor.readthedocs.io/en/latest/usage.html
    // https://thumbor.readthedocs.io/en/latest/usage.html#image-size
    // https://thumbor.readthedocs.io/en/latest/filters.html
    // http://localhost:8888/unsafe/filters:brightness(10):contrast(30)/https%3A%2F%2Fgithub.com%2Fthumbor%2Fthumbor%2Fraw%2Fmaster%2Fexample.jpg

    return (
        CDN_BASE_URL +
        `/${height}x${width}/filters:quality(${quality}):format(webp)/` +
        encodeURIComponent(src)
    );
}

function cloudinaryS3Loader({ src, width = null, quality }) {
    function getCloudinaryPath(url) {
        if (url.includes("inuua-staging.s3.amazonaws.com")) {
            return `${CLOUDINARY_S3_STAGING}/${getImagePath(url)}`;
        }
        return `${CLOUDINARY_S3_PROD}/${getImagePath(url)}`;
    }
    // https://cloudinary.com/documentation/media_optimizer_configuration#example_3_using_the_default_domain_and_an_aws_s3_media_source
    // https://cloudinary.com/documentation/transformation_reference
    // Example:
    // - https://inuua-staging.s3.amazonaws.com/original_images/4161_Phase1_Front.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXURR2KJD3QX5NYLZ%2F20220114%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220114T041421Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=e2563d5116855054de4490700e6456177f25ac93f33eaa7b3d77066412339bb0
    // - https://inuua.mo.cloudinary.net/staging/original_images/4161_Phase1_Front.jpg?tx=c_fit,h_100,w_100,f_webp,q_75
    return `${getCloudinaryPath(src)}?tx=c_fit${
        !!width ? ",w_" + width.toString() : ""
    },q_${quality}`;
}

// function cloudinaryWebLoader({ src, width, quality }) {
//     function getCloudinaryPath(url) {
//         if (BASE_URL == "https://staging.inuua.net") {
//             return `${CLOUDINARY_WEB_STAGING}${url}`;
//         }
//         return `${CLOUDINARY_WEB_PROD}${url}`;
//     }
//     // https://cloudinary.com/documentation/media_optimizer_configuration#example_3_using_the_default_domain_and_an_aws_s3_media_source
//     // https://cloudinary.com/documentation/transformation_reference
//     // Example:
//     // - https://inuua-staging.s3.amazonaws.com/original_images/4161_Phase1_Front.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXURR2KJD3QX5NYLZ%2F20220114%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220114T041421Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=e2563d5116855054de4490700e6456177f25ac93f33eaa7b3d77066412339bb0
//     // - https://inuua.mo.cloudinary.net/staging/original_images/4161_Phase1_Front.jpg?tx=c_fit,h_100,w_100,f_webp,q_75

//     return `${getCloudinaryPath(src)}?tx=c_fit,w_${width},q_${quality}`;
// }

export const imageLoader = ({ src, width = null, quality, cdn = "inuua" }) => {
    // if (src.startsWith("/") && BASE_URL.includes("inuua.net")) {
    //     return cloudinaryWebLoader({ src, width, quality });
    // } else
    // if (src.includes("s3.amazonaws.com")) {
    if (cdn == "cloudinary") {
        return cloudinaryS3Loader({ src, width, quality });
    }
    // return inuuaCDNLoader({ src, width: width ? width : 0, quality });
    // }
    // else if (!BASE_URL.includes("localhost")) {
    //     return cloudinaryS3Loader({
    //         src: `${BASE_URL}${src}?random_query=random_query`,
    //         width,
    //         quality,
    //     });
    // }
    // why? https://nextjs.org/docs/messages/next-image-missing-loader-width
    return `${src}`;
};

function MyImage({
    src,
    height,
    width,
    quality = 75,
    placeholder = "",
    blurDataURL = "",
    layout = "",
    alt = "",
    style = {},
    className = "",
    priority = false,
}) {
    return (
        <Image
            src={src.includes("s3.amazonaws.com") ? src : encodeURI(src)}
            height={height}
            width={width}
            alt={alt}
            // style={style}
            className={className}
            quality={quality}
            placeholder={placeholder}
            blurDataURL={blurDataURL}
            layout={layout}
            loader={src.includes("s3.amazonaws.com") ? imageLoader : undefined}
            priority={priority}
        />
    );
}

export default MyImage;
