// project wide configuration

const IN_DEV = process.env.NODE_ENV === "development";

export const BASE_URL =
    process.env.NEXT_PUBLIC_BASE_URL || IN_DEV
        ? "http://localhost:3000"
        : "https://www.inuua.net";

// <---------------------- Navbar ------------------------>
export const SMALL_NAVBAR_AND_FOOTER_PATHS = ["/partners", "/admin"];
export const NAVBAR_CUSTOM_LOGO_PATHS = {
    "/nachu": {
        path: "/images/nachu/InuuaNaNachuLogoPlusRiverLineRidgesLogo.png",
        height: "60px",
        width: "180px",
    },
};

// <---------------------- CDN ------------------------>
export const CDN_BASE_URL =
    "https://cdn.inuua.net/unsafe" || process.env.CDN_BASE_URL;
export const CLOUDINARY_S3_PROD =
    "https://inuua.mo.cloudinary.net/prod" ||
    process.env.NEXT_PUBLIC_CLOUDINARY_S3_PROD;
export const CLOUDINARY_S3_STAGING =
    "https://inuua.mo.cloudinary.net/staging" ||
    process.env.NEXT_PUBLIC_CLOUDINARY_S3_STAGING;
export const CLOUDINARY_WEB_PROD =
    "https://inuua.mo.cloudinary.net/web-prod" ||
    process.env.NEXT_PUBLIC_CLOUDINARY_WEB_PROD;
export const CLOUDINARY_WEB_STAGING =
    "https://inuua.mo.cloudinary.net/web-staging" ||
    process.env.NEXT_PUBLIC_CLOUDINARY_WEB_STAGING;

// <---------------------- Third party analytics ------------------------>
export const ANALYTICS_PLAN_VIEW_EVENT_NAME =
    "recordPlanView" || process.env.ANALYTICS_PLAN_VIEW_EVENT_NAME;
export const ANALYTICS_PLAN_VIEW_PROPERTIES_KEY =
    "planViewProperties" || process.env.ANALYTICS_PLAN_VIEW_PROPERTIES_KEY;
export const ANALYTICS_PLAN_SAVE_EVENT_NAME =
    "recordPlanSave" || process.env.ANALYTICS_PLAN_SAVE_EVENT_NAME;
export const ANALYTICS_PLAN_SAVE_PROPERTIES_KEY =
    "planSaveProperties" || process.env.ANALYTICS_PLAN_SAVE_PROPERTIES_KEY;

// <---------------------- Nachu ------------------------>

export const NACHU_PLAN_CATEGORIES = [
    // {
    //     name: "",
    //     pluralName: "",
    //     beds: "",
    //     showers: "",
    //     price: "",
    //     image: "",
    //     slug:"",
    //       description:"",
    // points: [],
    // },
    {
        name: "Inuua Chap Chap",
        pluralName: "Inuua Chap Chaps",
        beds: "1-4",
        showers: "1-3",
        price: "1.7M-4.8M",
        image: "images/nachu/renders/Render 3.jpg",
        slug: "jengapolepole",
        description: "",
        points: [
            "Start with a two bedroom and build your dream home over time!",
            "Inuua na NACHU introduce you to financiers",
            "Use the Inuua App to help build your home in 6 months",
            "Move in and replace rent for re-payments from from 50k a month for 3-4 years",
            // "The phase by phase building approach allows you to build as your family needs change and as your finances allow.",
            // "Most important however, it’s your home. No rent, less worries.",
            // "Starting from the 30 x 60 to 40 x 80 and 50 x 100, the Inuua Chap Chap designs bring your dream home within reach stage by stage!",
        ],
    },
    {
        name: "Bungalow",
        pluralName: "Bungalows",
        beds: "2-4",
        showers: "2-3",
        price: "2.7M-5.3M",
        image: "images/nachu/renders/Render 2 .jpg",
        slug: "bungalow",
        description: "",
        points: [
            "Our Bungalow house plans are suited for rural and urban areas alike.",
            "From the modern to the more classical architectural designs, you choose knowing that factors, from looks to costs are in balance!",
            // "The 2 beds can fit on plots smaller than 30 x 60, but we have a variety suited well to our Kenyan plot sizes.",
        ],
    },
    {
        name: "Maisonette",
        pluralName: "Maisonettes",
        beds: "3-5",
        showers: "3+",
        price: "4.7M-6.9M",
        image: "images/MaisonetteHomePage.jpg",
        slug: "maisonette",
        description: "",
        points: [
            "Our 3 bedroom and 4 bedroom maisonette floor plans, are designed to ensure costs of building a home are within all our reach.",
            "Architectural designs vary from the conventional roofs to the more modern flat roofs for different floor plans.",
            // "Our home plans are designed to fit on plots from 30 x 60 with options to add a DSQ or home office module.",
            "The home plans are selected to fit on the plots at Riverline Ridges",
        ],
    },
    {
        name: "Mansion",
        pluralName: "Mansions",
        beds: "3-5",
        showers: "3+",
        price: "4.7M-6.9M",
        image: "images/nachu/renders/Render 1.jpg",
        slug: "mansion",
        description: "",
        points: [
            "Our 3 bedroom and 4 bedroom mansion floor plans, are designed to ensure costs of building a home are within all our reach.",
            "Architectural designs vary from the conventional roofs to the more modern flat roofs for different floor plans.",
            // "Our home plans are designed to fit on plots from 30 x 60 with options to add a DSQ or home office module.",
            "The home plans are selected to fit on the plots at Riverline Ridges",
        ],
    },
];

// <---------------------- Kenya ------------------------>

export const KENYAN_PLAN_CATEGORIES = [
    // {
    //     name: "",
    //     pluralName: "",
    //     beds: "",
    //     showers: "",
    //     price: "",
    //     image: "",
    //     slug:"",
    //       description:"",
    // points: [],
    // },
    {
        name: "Inuua Chap Chap",
        pluralName: "Inuua Chap Chaps",
        beds: "1-4",
        showers: "1-3",
        price: "1.7M-4.8M",
        image: "images/jenga pole pole HomePage.jpg",
        slug: "inuua-chap-chap",
        description: "",
        points: [
            "Start with a one bedroom and build your dream home over time!",
            "The phase by phase building approach allows you to build as your family needs change and as your finances allow.",
            "Most important however, it’s your home. No rent, less worries.",
            "Starting from the 30 x 60 to 40 x 80 and 50 x 100, the Inuua Chap Chap designs bring your dream home within reach stage by stage!",
        ],
    },
    {
        name: "Maisonette",
        pluralName: "Maisonettes",
        beds: "3-5",
        showers: "3+",
        price: "4.7M-6.9M",
        image: "images/MaisonetteHomePage.jpg",
        slug: "maisonette",
        description: "",
        points: [
            "Our 3 bedroom and 4 bedroom maisonette floor plans, are designed to ensure costs of building a home are within all our reach.",
            "Architectural designs vary from the conventional roofs to the more modern flat roofs for different floor plans.",
            "Our home plans are designed to fit on plots from 30 x 60 with options to add a DSQ or home office module.",
        ],
    },
    {
        name: "Mansion",
        pluralName: "Mansions",
        beds: "3-5",
        showers: "3+",
        price: "4.7M-6.9M",
        image: "images/MaisonetteHomePage.jpg",
        slug: "mansion",
        description: "",
        points: [
            "Our 3 bedroom and 4 bedroom mansion floor plans, are designed to ensure costs of building a home are within all our reach.",
            "Architectural designs vary from the conventional roofs to the more modern flat roofs for different floor plans.",
            "Our home plans are designed to fit on plots from 30 x 60 with options to add a DSQ or home office module.",
        ],
    },
    {
        name: "Bungalow",
        pluralName: "Bungalows",
        beds: "2-4",
        showers: "2-3",
        price: "2.7M-5.3M",
        image: "images/BungalowHomePage.jpg",
        slug: "bungalow",
        description: "",
        points: [
            "Our Bungalow house plans are suited for rural and urban areas alike.",
            "From the modern to the more classical architectural designs, you choose knowing that factors, from looks to costs are in balance!",
            "The 2 beds can fit on plots smaller than 30 x 60, but we have a variety suited well to our Kenyan plot sizes.",
        ],
    },
];
