import {
    UPLOAD_LOAN_DOCUMENTS_FAILED,
    UPLOAD_LOAN_DOCUMENTS_LOADING,
    UPLOAD_LOAN_DOCUMENTS_SUCCESS,
    SAVE_LOAN_DETAILS_SUCCESS,
    SAVE_LOAN_DETAILS_FAILED,
    SAVE_LOAN_DETAILS_LOADING,
    GET_LOAN_APPLICATIONS_SUCCESS,
    GET_LOAN_APPLICATIONS_FAILED,
    GET_LOAN_APPLICATIONS_LOADING,
} from "@actions/dashboard/types";
import { Action } from "@src/interfaces/redux";
import { LoanApplication } from "@src/interfaces/bank";

interface InitialState {
    uploadLoanApplicationDocumentsLoading: boolean;
    uploadLoanApplicationDocumentsFailed: boolean;
    saveLoanDetailsLoading: boolean;
    saveLoanDetailsFailed: boolean;
    getLoanApplicationsLoading: boolean;
    getLoanApplicationsFailed: boolean;
    loanApplications: LoanApplication[];
}
const initialState: InitialState = {
    uploadLoanApplicationDocumentsLoading: false,
    uploadLoanApplicationDocumentsFailed: false,
    saveLoanDetailsLoading: false,
    saveLoanDetailsFailed: false,
    getLoanApplicationsLoading: false,
    getLoanApplicationsFailed: false,
    loanApplications: [],
};

export default function dashboardReducer(
    state = initialState,
    action: Action
): InitialState {
    switch (action.type) {
        case UPLOAD_LOAN_DOCUMENTS_SUCCESS:
            return {
                ...state,
                uploadLoanApplicationDocumentsLoading: false,
                uploadLoanApplicationDocumentsFailed: false,
            };
        case UPLOAD_LOAN_DOCUMENTS_FAILED:
            return {
                ...state,
                uploadLoanApplicationDocumentsLoading: false,
                uploadLoanApplicationDocumentsFailed: true,
            };
        case UPLOAD_LOAN_DOCUMENTS_LOADING:
            return {
                ...state,
                uploadLoanApplicationDocumentsLoading: true,
                uploadLoanApplicationDocumentsFailed: false,
            };
        //
        case GET_LOAN_APPLICATIONS_SUCCESS:
            return {
                ...state,
                loanApplications: action.payload.results,
                getLoanApplicationsLoading: false,
                getLoanApplicationsFailed: false,
            };
        case GET_LOAN_APPLICATIONS_FAILED:
            return {
                ...state,
                loanApplications: [],
                getLoanApplicationsLoading: false,
                getLoanApplicationsFailed: true,
            };
        case GET_LOAN_APPLICATIONS_LOADING:
            return {
                ...state,
                getLoanApplicationsLoading: true,
                getLoanApplicationsFailed: false,
            };
        //
        case SAVE_LOAN_DETAILS_SUCCESS:
            return {
                ...state,
                saveLoanDetailsLoading: false,
                saveLoanDetailsFailed: false,
            };
        case SAVE_LOAN_DETAILS_FAILED:
            return {
                ...state,
                saveLoanDetailsLoading: false,
                saveLoanDetailsFailed: true,
            };
        case SAVE_LOAN_DETAILS_LOADING:
            return {
                ...state,
                saveLoanDetailsLoading: true,
                saveLoanDetailsFailed: false,
            };
        //
        default:
            return state;
    }
}
