import React, { ReactElement } from "react";
import Head from "next/head";
import Script from "next/script";
import { useDispatch, useSelector } from "@src/hooks/redux";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import { SWRConfig } from "swr";
import axios from "axios";
//
import { GTM_ID } from "@src/lib/gtm";
import { GA_TRACKING_ID } from "@src/lib/ga";
import API_URL from "@utils/apiUrl";
import errorHandler from "@actions/errorHandler";
import DefaultSEO from "@seo/DefaultSEO";
import { defaultKeyWords } from "@seo/seoConfig";
import Alerts from "@templates/Alerts/Alerts";
import AlertTemplate from "@templates/Alerts/AlertTemplate";
import AutoAuth from "@organisms/Auth/AutoAuth";
import NavBar from "@layout/NavBar/NavBar";
import Footer from "@layout/Footer/Footer";

// optional configuration for react-alert
const alertOptions = {
    position: positions.TOP_RIGHT,
    offset: "15px",
    transition: transitions.FADE,
    timeout: 4000,
};

// const Alerts = dynamic(() => import("@templates/Alerts/Alerts"), {
//     ssr: false,
// });
// const AlertTemplate = dynamic(
//     () => import("@templates/Alerts/AlertTemplate"),
//     {
//         ssr: false,
//     }
// );

interface Props {
    children?: ReactElement;
}

export default function App(props: Props): ReactElement {
    const dispatch = useDispatch();
    const authToken = useSelector((state) => state.authReducer.token);

    // swr config
    const swrConfig = {
        errorRetryCount: 5,
        fetcher: (url: string) =>
            axios({
                method: "get",
                url: url,
                baseURL: API_URL,
                headers: !authToken
                    ? { "Content-Type": "application/json" }
                    : {
                          "Content-Type": "application/json",
                          Authorization: `Token ${authToken}`,
                      },

                // responseType: 'stream'
            }).then((res) => res.data),

        onError: (error: object, key: string) => {
            // console.log(error, key);
            errorHandler(dispatch, error);
        },
    };

    return (
        <>
            <AppHead />
            {/* {process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT == // TODO: enable in prod only
                        "PRODUCTION" && ( */}

            <>
                {/* <!-------------------  Global Site Tag (gtag.js) - Google Analytics -----------------------------> */}
                <>
                    <Script
                        strategy="afterInteractive"
                        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
                    />
                    <Script
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                            __html: `
                                    window.dataLayer = window.dataLayer || [];
                                    function gtag(){dataLayer.push(arguments);}
                                    gtag('js', new Date());
                                    gtag('config', '${GA_TRACKING_ID}', {
                                    page_path: window.location.pathname,
                                    });
                                `,
                        }}
                    />
                </>
                {/* <!-------------------  End Global Site Tag (gtag.js) - Google Analytics -----------------------------> */}

                {/* <!------------------- Google Tag Manager -----------------------------> */}
                <>
                    <Script
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                            __html: `dataLayer = [];`,
                        }}
                    />
                    <Script
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                            __html: `
                                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                })(window,document,'script','dataLayer', '${GTM_ID}');
                                `,
                        }}
                    />
                </>
                {/* <!------------------- End Google Tag Manager -----------------------------> */}
            </>

            {/* )} */}
            <DefaultSEO />
            <>
                <AlertProvider template={AlertTemplate} {...alertOptions}>
                    <SWRConfig value={swrConfig}>
                        <Alerts />
                        <AutoAuth />
                        <NavBar />

                        <main className="page-main">{props.children}</main>

                        <Footer />
                    </SWRConfig>
                </AlertProvider>
            </>
        </>
    );
}

function AppHead(): ReactElement {
    return (
        <Head>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta
                name="viewport"
                content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
            />

            {/* seo (the important SEO is done using the DefaultSEO component below ) */}
            {/* this is not that important  */}
            <meta name="keywords" content={defaultKeyWords} />

            {/*  manifest */}
            <link rel="manifest" href="/manifest.json" />
            <link rel="apple-touch-icon" href="/apple-touch-icon.png"></link>
            <meta name="theme-color" content="#317EFB" />

            {/* css */}
            <link
                rel="stylesheet"
                href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
                integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
                crossOrigin="anonymous"
            />
            <link
                href="https://fonts.googleapis.com/css?family=Open+Sans:400,600&amp;subset=latin-ext&display=swap"
                rel="stylesheet"
            />
        </Head>
    );
}
