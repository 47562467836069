import {
    SAVE_INITIATIVE_USER_SUCCESS,
    SAVE_INITIATIVE_USER_FAILED,
    SAVE_INITIATIVE_USER_LOADING,
    GET_INITIATIVES_SUCCESS,
    GET_INITIATIVES_FAILED,
    GET_INITIATIVES_LOADING,
} from "@actions/initiatives/types";
import { Action } from "@src/interfaces/redux";
import { Initiative } from "@src/interfaces/initiatives";

interface InitialState {
    uploadLoanApplicationDocumentsLoading: boolean;
    uploadLoanApplicationDocumentsFailed: boolean;
    saveInitiativeUserLoading: boolean;
    saveInitiativeUserFailed: boolean;
    getInitiativesLoading: boolean;
    getInitiativesFailed: boolean;
    initiatives: Initiative[];
}
const initialState: InitialState = {
    uploadLoanApplicationDocumentsLoading: false,
    uploadLoanApplicationDocumentsFailed: false,
    saveInitiativeUserLoading: false,
    saveInitiativeUserFailed: false,
    getInitiativesLoading: false,
    getInitiativesFailed: false,
    initiatives: [],
};

export default function dashboardReducer(
    state = initialState,
    action: Action
): InitialState {
    switch (action.type) {
        case GET_INITIATIVES_SUCCESS:
            return {
                ...state,
                initiatives: action.payload.results,
                getInitiativesLoading: false,
                getInitiativesFailed: false,
            };
        case GET_INITIATIVES_FAILED:
            return {
                ...state,
                initiatives: [],
                getInitiativesLoading: false,
                getInitiativesFailed: true,
            };
        case GET_INITIATIVES_LOADING:
            return {
                ...state,
                getInitiativesLoading: true,
                getInitiativesFailed: false,
            };
        //
        case SAVE_INITIATIVE_USER_SUCCESS:
            return {
                ...state,
                saveInitiativeUserLoading: false,
                saveInitiativeUserFailed: false,
            };
        case SAVE_INITIATIVE_USER_FAILED:
            return {
                ...state,
                saveInitiativeUserLoading: false,
                saveInitiativeUserFailed: true,
            };
        case SAVE_INITIATIVE_USER_LOADING:
            return {
                ...state,
                saveInitiativeUserLoading: true,
                saveInitiativeUserFailed: false,
            };
        //
        default:
            return state;
    }
}
