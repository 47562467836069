import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

export const init = () => {
    if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
        Sentry.init({
            enabled: process.env.NODE_ENV === "production",
            dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
            release: process.env.NEXT_PUBLIC_COMMIT_SHA,
            integrations: [new Integrations.BrowserTracing()],
            autoSessionTracking: true,
            environment:
                process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT || "STAGING",

            tracesSampleRate:
                process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT == "PRODUCTION"
                    ? 0.3
                    : 1.0,
        });
    }
};
