export const UPLOAD_LOAN_DOCUMENTS_SUCCESS = "UPLOAD_LOAN_DOCUMENTS_SUCCESS";
export const UPLOAD_LOAN_DOCUMENTS_FAILED = "UPLOAD_LOAN_DOCUMENTS_FAILED";
export const UPLOAD_LOAN_DOCUMENTS_LOADING = "UPLOAD_LOAN_DOCUMENTS_LOADING";
//
export const GET_LOAN_APPLICATIONS_SUCCESS = "GET_LOAN_APPLICATIONS_SUCCESS";
export const GET_LOAN_APPLICATIONS_FAILED = "GET_LOAN_APPLICATIONS_FAILED";
export const GET_LOAN_APPLICATIONS_LOADING = "GET_LOAN_APPLICATIONS_LOADING";
//
export const SAVE_LOAN_DETAILS_SUCCESS = "SAVE_LOAN_DETAILS_SUCCESS";
export const SAVE_LOAN_DETAILS_FAILED = "SAVE_LOAN_DETAILS_FAILED";
export const SAVE_LOAN_DETAILS_LOADING = "SAVE_LOAN_DETAILS_LOADING";
