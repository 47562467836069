import {
    GET_PROJECTS_FAILED,
    GET_PROJECTS_LOADING,
    GET_PROJECTS_SUCCESS,
} from "../../actions/projects/types";
import { Action } from "@src/interfaces/redux";
import { Project } from "@src/interfaces/projects";

interface InitialState {
    projects: Project[];
    getProjectsLoading: boolean;
    getProjectsFailed: boolean;
}
const initialState: InitialState = {
    projects: [],
    getProjectsLoading: false,
    getProjectsFailed: false,
};

export default function projectsReducer(
    state = initialState,
    action: Action
): InitialState {
    switch (action.type) {
        case GET_PROJECTS_SUCCESS:
            return {
                ...state,
                projects: action.payload.results,
                getProjectsLoading: false,
                getProjectsFailed: false,
            };
        case GET_PROJECTS_FAILED:
            return {
                ...state,
                getProjectsLoading: false,
                getProjectsFailed: true,
                projects: [],
            };
        case GET_PROJECTS_LOADING:
            return {
                ...state,
                getProjectsLoading: true,
                getProjectsFailed: false,
            };
        default:
            return state;
    }
}
