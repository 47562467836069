import { recordUserIdGTM, recordEventGTM, resetValueGTM } from "../../lib/gtm";
import * as Sentry from "@sentry/browser";

export function recordUserId(id) {
    process.env.NODE_ENV === "development" && console.log("recordUserId", id);

    recordUserIdGTM(id);
    Sentry.setUser({ id: id });
}

export default function recordUser(
    id,
    email,
    phone_number,
    first_name,
    last_name,
    location
) {
    //
    const user = {
        phone_number: phone_number,
        location: location,
        first_name: first_name,
        last_name: last_name,
        id: id,
        email: email,
    };
    process.env.NODE_ENV === "development" &&
        console.log("recordUser", "userProperties", user);

    //
    recordUserIdGTM(id);
    Sentry.setUser(user);
    return recordEventGTM("recordUser", "userProperties", user);
}

export function clearUser() {
    process.env.NODE_ENV === "development" && console.log("clearUser");

    Sentry.configureScope((scope) => scope.setUser(null));

    recordUserIdGTM(undefined);
    resetValueGTM("userProperties");
    return recordEventGTM("clearUser");
}
