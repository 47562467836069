import {
    CREATE_MESSAGE,
    GET_ERRORS,
    SHOW_NOTIFICATION,
} from "../../actions/messages/types";
// import { Alert } from "react-native";

const initialState = {
    title: "",
    message: "",
    type: "", // 'success', 'danger', 'info'
    time: "",
};

// function to verify object
function isObject(item) {
    return typeof item === "object" && !Array.isArray(item) && item !== null;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const showAlert = (message, title, type) => {
    var alertType = type == null ? "info" : type;

    if (title) {
        return {
            title: title,
            message: message,
            type: alertType,
            time: new Date().toISOString(),
        };
    } else {
        return {
            title: "",
            message: message,
            type: alertType,
            time: new Date().toISOString(),
        };
    }
};

export default function messages(state = initialState, action) {
    switch (action.type) {
        //
        case CREATE_MESSAGE:
            return {
                ...state,
                ...showAlert(action.payload.msg, null, action.payload.type),
            };
        //
        case SHOW_NOTIFICATION:
            return {
                ...state,
                ...showAlert(action.payload.msg, action.payload.title),
            };
        // ERRORS
        // handle errors from django
        case GET_ERRORS:
            // add title
            var title = "";
            if (action.payload.title) {
                title = action.payload.title;
            }

            if (process.env.NODE_ENV === "development") {
                console.log(action.payload.msg);
            }

            var err = action.payload.msg;

            if (isObject(err)) {
                //  deal with non field errors
                if (err.non_field_errors) {
                    err = err.non_field_errors[0];
                } else if (err.error && err.error.error) {
                    err = err.error.error;
                }
                // convert object to string
                else {
                    var str = "";
                    // convert err object to array [ key, val]
                    var err_array = Object.entries(err);
                    err_array.map((obj) => {
                        var key = obj[0];
                        var val = obj[1];
                        if (typeof val === "string") {
                            // convert array to string
                            // replace _ with ' '
                            str =
                                str +
                                capitalizeFirstLetter(key.replace(/_/g, " ")) +
                                ": " +
                                val +
                                "\n\n";
                        } else if (Array.isArray(val)) {
                            // convert array to string
                            // replace _ with ' '
                            str =
                                str +
                                capitalizeFirstLetter(key.replace(/_/g, " ")) +
                                ": " +
                                val[0].toString() +
                                "\n\n";
                        }
                    });
                    err = str;
                }
            } else if (typeof err === "string") {
                //
                if (err.includes("<!DOCTYPE html>")) {
                    err = "Failed: " + action.payload.title;
                }
            }

            return {
                ...state,
                ...showAlert(err, "ERROR: " + title, "danger"),
            };
        default:
            return state;
    }
}
