import { combineReducers } from "redux";

// reducers
import auth from "./auth/auth";
import admin from "./admin/admin";
import plans from "./plans/plans";
import projects from "./projects/projects";
import messages from "./messages/messages";
import checkout from "./checkout/checkout";
import dashboard from "./dashboard/dashboard";
import customers from "./customers/customers";
import initiatives from "./initiatives/initiatives";

/**
 *  reducer used to track the last action dispatched
 */
function lastActionDispatched(state = null, action) {
    return action;
}

const rootReducer = combineReducers({
    lastActionDispatched,
    authReducer: auth,
    adminReducer: admin,
    plansReducer: plans,
    projectsReducer: projects,
    messagesReducer: messages,
    checkoutReducer: checkout,
    dashboardReducer: dashboard,
    customersReducer: customers,
    initiativesReducer: initiatives,
});

export default rootReducer;
