import {
    GET_MY_PLANS_FAILED,
    GET_MY_PLANS_LOADING,
    GET_MY_PLANS_SUCCESS,
    GET_PLANS_FAILED,
    GET_PLANS_LOADING,
    GET_PLANS_SUCCESS,
    SHARE_PLAN_SUCCESS,
    SHARE_PLAN_FAILED,
    SHARE_PLAN_LOADING,
    ADMIN_SHARE_PLAN_SUCCESS,
    ADMIN_SHARE_PLAN_FAILED,
    ADMIN_SHARE_PLAN_LOADING,
    GET_SAVED_PLANS_FAILED,
    GET_SAVED_PLANS_LOADING,
    GET_SAVED_PLANS_SUCCESS,
    REMOVE_SAVED_PLAN_FAILED,
    REMOVE_SAVED_PLAN_LOADING,
    REMOVE_SAVED_PLAN_SUCCESS,
    ADD_SAVED_PLAN_SUCCESS,
    ADD_SAVED_PLAN_FAILED,
    ADD_SAVED_PLAN_LOADING,
} from "../../actions/plans/types";

const initialState = {
    getMyPlansLoading: false,
    getMyPlansFailed: false,
    myPlans: [],
    //
    getPlansLoading: false,
    getPlansFailed: false,
    plans: [],
    //
    sharePlanLoading: false,
    sharePlanFailed: false,
    //
    adminSharePlanLoading: false,
    adminSharePlanFailed: false,
    //
    getSavedPlansLoading: false,
    getSavedPlansFailed: false,
    savedPlans: [],
    //
    addSavedPlanLoading: false,
    addSavedPlanFailed: false,
    //
    removeSavedPlanLoading: false,
    removeSavedPlanFailed: false,
};

export default function plans(state = initialState, action) {
    switch (action.type) {
        case GET_PLANS_SUCCESS:
            return {
                ...state,
                plans: action.payload.results,
                getPlansLoading: false,
                getPlansFailed: false,
            };
        case GET_PLANS_FAILED:
            return {
                ...state,
                getPlansLoading: false,
                getPlansFailed: true,
                plans: [],
            };
        case GET_PLANS_LOADING:
            return {
                ...state,
                getPlansLoading: true,
                getPlansFailed: false,
            };
        //
        case GET_MY_PLANS_SUCCESS:
            return {
                ...state,
                myPlans: action.payload.results,
                getMyPlansLoading: false,
                getMyPlansFailed: false,
            };
        case GET_MY_PLANS_FAILED:
            return {
                ...state,
                getMyPlansLoading: false,
                getMyPlansFailed: true,
                myPlans: [],
            };
        case GET_MY_PLANS_LOADING:
            return {
                ...state,
                getMyPlansLoading: true,
                getMyPlansFailed: false,
            };
        //
        case ADMIN_SHARE_PLAN_SUCCESS:
            return {
                ...state,
                adminSharePlanLoading: false,
                adminSharePlanFailed: false,
            };
        case ADMIN_SHARE_PLAN_FAILED:
            return {
                ...state,
                adminSharePlanLoading: false,
                adminSharePlanFailed: true,
            };
        case ADMIN_SHARE_PLAN_LOADING:
            return {
                ...state,
                adminSharePlanLoading: true,
                adminSharePlanFailed: false,
            };
        //
        case SHARE_PLAN_SUCCESS:
            return {
                ...state,
                sharePlanLoading: false,
                sharePlanFailed: false,
            };
        case SHARE_PLAN_FAILED:
            return {
                ...state,
                sharePlanLoading: false,
                sharePlanFailed: true,
            };
        case SHARE_PLAN_LOADING:
            return {
                ...state,
                sharePlanLoading: true,
                sharePlanFailed: false,
            };
        //
        case REMOVE_SAVED_PLAN_SUCCESS:
            return {
                ...state,
                removeSavedPlanLoading: false,
                removeSavedPlanFailed: false,
            };
        case REMOVE_SAVED_PLAN_FAILED:
            return {
                ...state,
                removeSavedPlanLoading: false,
                removeSavedPlanFailed: true,
            };
        case REMOVE_SAVED_PLAN_LOADING:
            return {
                ...state,
                removeSavedPlanLoading: true,
                removeSavedPlanFailed: false,
            };
        //
        case GET_SAVED_PLANS_SUCCESS:
            return {
                ...state,
                savedPlans: action.payload,
                getSavedPlansLoading: false,
                getSavedPlansFailed: false,
            };
        case GET_SAVED_PLANS_FAILED:
            return {
                ...state,
                getSavedPlansLoading: false,
                getSavedPlansFailed: true,
                savedPlans: [],
            };
        case GET_SAVED_PLANS_LOADING:
            return {
                ...state,
                getSavedPlansLoading: true,
                getSavedPlansFailed: false,
            };
        //
        case ADD_SAVED_PLAN_SUCCESS:
            return {
                ...state,
                addSavedPlanLoading: false,
                addSavedPlanFailed: false,
            };
        case ADD_SAVED_PLAN_FAILED:
            return {
                ...state,
                addSavedPlanLoading: false,
                addSavedPlanFailed: true,
            };
        case ADD_SAVED_PLAN_LOADING:
            return {
                ...state,
                addSavedPlanLoading: true,
                addSavedPlanFailed: false,
            };
        //

        default:
            return state;
    }
}
