import React, { useState } from "react";
import Headroom from "react-headroom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navbar, Nav } from "react-bootstrap";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "@image";
//
import { logout } from "../../../../state/actions/auth/auth";
import {
    SMALL_NAVBAR_AND_FOOTER_PATHS,
    NAVBAR_CUSTOM_LOGO_PATHS,
} from "../../../../settings";

function LogoImage({ pathname }) {
    if (!!pathname) {
        const path = "/" + pathname.split("/")[1];

        console.log(path, pathname);
        if (NAVBAR_CUSTOM_LOGO_PATHS[path]) {
            const pathObj = NAVBAR_CUSTOM_LOGO_PATHS[path];
            return (
                <Link href={path}>
                    <a className="">
                        <Image
                            className="common"
                            src={pathObj.path}
                            height={pathObj.height}
                            width={pathObj.width}
                            alt="Logo"
                        />
                    </a>
                </Link>
            );
        }
    }

    return (
        <Link href="/">
            <a className="navbar-brand @@logo_classes">
                <Image
                    className="common"
                    src={
                        pathname == "/"
                            ? "/images/logo-white.png"
                            : "/images/logo.png"
                    }
                    height="35"
                    width="170"
                    alt="Logo"
                    quality={50}
                    priority
                />
            </a>
        </Link>
    );
}

export const NavBarComponent = (props) => {
    const router = useRouter();
    const [isExpanded, setisExpanded] = useState(false);
    const [isDark, setisDark] = useState(false);

    function onToggle(isExpandedBool) {
        setisExpanded(isExpandedBool);
        // console.log(isExpandedBool);

        // delay removing dark
        if (isExpandedBool) {
            setisDark(isExpandedBool);
        } else {
            setTimeout(function () {
                setisDark(isExpandedBool);
            }, 1000);
        }
    }

    // only on browser
    if (typeof window != "undefined") {
        const closeNavPopup = () => {
            onToggle(false);
        };

        // collapse nav popup on route change
        router.events.on("routeChangeStart", closeNavPopup);
    }

    function showLinks(ready) {
        if (ready) {
            for (const restrictedPath of SMALL_NAVBAR_AND_FOOTER_PATHS) {
                if (router.pathname.startsWith(restrictedPath)) {
                    return false;
                }
            }
        }

        return true;
    }

    return (
        <header className="header-global">
            <Headroom
                style={{ zIndex: 5 }}
                disable={!showLinks(router.isReady)}
            >
                <Navbar
                    expand="lg"
                    collapseOnSelect={true}
                    expanded={isExpanded}
                    className={"py-lg-3 px-lg-5"}
                    bg={router.pathname == "/" ? "dark" : ""}
                    variant={router.pathname == "/" ? "dark" : "light"}
                    style={{
                        backgroundColor: router.pathname == "/" ? "" : "white",
                    }}
                    onToggle={onToggle}
                >
                    {/* <-------------------------------------- logo  --------------------------------------> */}
                    <LogoImage pathname={router.pathname} />

                    {/* <-------------------------------------- dropdown content  --------------------------------------> */}
                    <Navbar.Collapse
                        id="responsive-navbar-nav"
                        className={isDark ? "bg-gradient-black" : ""}
                    >
                        <Nav className="mr-auto">
                            {isExpanded && (
                                <div className="d-flex justify-content-between mb-1">
                                    {/* logo  */}
                                    <LogoImage pathname={router.pathname} />

                                    {/* dropdown button  */}
                                    <Navbar.Toggle aria-controls="responsive-navbar-nav">
                                        <i
                                            className="fa fa-times fa-lg"
                                            aria-hidden="true"
                                        ></i>
                                    </Navbar.Toggle>
                                </div>
                            )}
                        </Nav>
                        {showLinks(router.isReady) ? (
                            <>
                                <Nav className="ml-auto">
                                    <Link href="/">
                                        <a
                                            className={
                                                router.pathname !== "/"
                                                    ? "nav-link"
                                                    : "nav-link text-light"
                                            }
                                        >
                                            Home
                                        </a>
                                    </Link>
                                    <Link href="/plans">
                                        <a
                                            className={
                                                router.pathname !== "/"
                                                    ? "analytics-view-plans-link nav-link"
                                                    : "analytics-view-plans-link nav-link text-light"
                                            }
                                        >
                                            Browse Plans
                                        </a>
                                    </Link>

                                    <Link href="/blog">
                                        <a
                                            className={
                                                router.pathname !== "/"
                                                    ? "nav-link"
                                                    : "nav-link text-light"
                                            }
                                        >
                                            Blog
                                        </a>
                                    </Link>

                                    {!props.isAuthenticated && (
                                        <Link
                                            href={{
                                                pathname: "/accounts/login",
                                                query: { next: router.asPath },
                                            }}
                                        >
                                            <a
                                                className={
                                                    router.pathname !== "/"
                                                        ? "nav-link"
                                                        : "nav-link text-light"
                                                }
                                            >
                                                Login
                                            </a>
                                        </Link>
                                    )}

                                    {props.isAuthenticated && (
                                        <Link href="/plans/purchased-plans/">
                                            <a
                                                className={
                                                    router.pathname !== "/"
                                                        ? "nav-link"
                                                        : "nav-link text-light"
                                                }
                                            >
                                                My Plans
                                            </a>
                                        </Link>
                                    )}

                                    <Link href="/plans/saved-plans/">
                                        <a
                                            className={
                                                router.pathname !== "/"
                                                    ? "nav-link"
                                                    : "nav-link text-light"
                                            }
                                        >
                                            Saved Plans
                                        </a>
                                    </Link>

                                    {/* {props.isAuthenticated && (
                                // <Link href="/projects/">
                                <a
                                    className={
                                        router.pathname !== "/"
                                            ? "analytics-inuua-main-app-on-playstore-link nav-link"
                                            : "analytics-inuua-main-app-on-playstore-link nav-link text-light"
                                    }
                                    href="https://play.google.com/store/apps/details?id=com.inuuaprojectmanagement"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    My projects
                                </a>
                                // </Link>
                            )} */}

                                    {props.isAuthenticated && (
                                        <a
                                            onClick={props.logout}
                                            style={{ cursor: "pointer" }}
                                            className={"nav-link"}
                                        >
                                            Logout
                                        </a>
                                    )}

                                    <Link href="/contact">
                                        <a
                                            className={
                                                router.pathname !== "/"
                                                    ? "nav-link"
                                                    : "nav-link text-light"
                                            }
                                        >
                                            Contact Us
                                        </a>
                                    </Link>
                                </Nav>
                            </>
                        ) : (
                            <>
                                {!props.isAuthenticated && (
                                    <Link
                                        href={{
                                            pathname: "/accounts/login",
                                            query: { next: router.asPath },
                                        }}
                                    >
                                        <a
                                            className={
                                                router.pathname !== "/"
                                                    ? "nav-link"
                                                    : "nav-link text-light"
                                            }
                                        >
                                            Login
                                        </a>
                                    </Link>
                                )}
                                {props.isAuthenticated && (
                                    <a
                                        onClick={props.logout}
                                        style={{ cursor: "pointer" }}
                                        className={"nav-link "}
                                    >
                                        Logout
                                    </a>
                                )}
                            </>
                        )}
                    </Navbar.Collapse>

                    {/* dropdown button  */}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                </Navbar>
            </Headroom>
        </header>
    );
};

NavBarComponent.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.authReducer.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(NavBarComponent);
