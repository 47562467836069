import { CREATE_MESSAGE, GET_ERRORS, SHOW_NOTIFICATION } from "./types";

/**
 * CREATE MESSAGE
 * @param {String} msg Message to be displayed
 * @param {String} [type="success"] one of ['success', 'danger', 'info']
 */
export const createMessage = (msg, type = "success") => {
    if (process.env.NODE_ENV === "development") {
        console.log("MESSAGE:");
        console.log(msg);
    }
    return {
        type: CREATE_MESSAGE,
        payload: { msg, type },
    };
};

export const createErrorMessage = (msg) => {
    return createMessage(msg, "danger");
};

/**
 * RETURN ERRORS
 * @param {*} msg - Message to be displayed
 * @param {*} status - Server response status
 * @param {*} title - Title to be displayed
 */
export const returnErrors = (msg, status, title) => {
    if (process.env.NODE_ENV === "development") {
        console.log("ERROR:");
        console.log("title", title);
        console.log("msg", msg);
        console.log("status", status);
    }
    return {
        type: GET_ERRORS,
        payload: { msg, status, title },
    };
};

/**
 * CREATE NOTIFICATION
 * @param {*} title - Title to be displayed
 * @param {*} msg - Message to be displayed
 * @param {String} [type="info"] one of ['success', 'danger', 'info']
 */
export const showNotification = (title, msg, type = "info") => {
    if (process.env.NODE_ENV === "development") {
        console.log("showNotification:");
        console.log(msg);
    }
    return {
        type: SHOW_NOTIFICATION,
        payload: {
            title: title,
            msg: msg,
            type: type,
        },
    };
};
