import Link from "next/link";
import React from "react";
import Image from "@image";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { SMALL_NAVBAR_AND_FOOTER_PATHS } from "../../../../settings";

function Footer(props) {
    const router = useRouter();
    const isAuthenticated = useSelector(
        (state) => state.authReducer.isAuthenticated
    );

    var d = new Date();
    var year = d.getFullYear();

    function showLinks(ready) {
        if (ready) {
            for (const restrictedPath of SMALL_NAVBAR_AND_FOOTER_PATHS) {
                if (router.pathname.startsWith(restrictedPath)) {
                    return false;
                }
            }
        }

        return true;
    }

    return (
        <footer className="footer section pt-6 pt-md-6 pt-lg-6 pb-3 bg-dark text-light overflow-hidden">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 mb-4 mb-lg-0">
                        <Link className="footer-brand mr-lg-5 d-flex" href="/">
                            <a>
                                <Image
                                    src="/images/logo-white.png"
                                    height="35"
                                    width="170"
                                    // className="mr-3"
                                    alt="Footer logo"
                                />
                            </a>
                        </Link>
                        <p className="my-4">
                            Start with our house plans and build your home in
                            stages saving up to 40% managing your site.
                        </p>
                    </div>
                    {showLinks(router.isReady) && (
                        <div className="col-6 col-sm-6 col-lg-2 mb-4 mb-lg-0">
                            <ul className="links-vertical text-white">
                                <li>
                                    <Link href="/plans/">
                                        <a className="analytics-view-plans-link">
                                            Browse Plans
                                        </a>
                                    </Link>
                                </li>
                                {!isAuthenticated && (
                                    <li>
                                        <Link
                                            href={{
                                                pathname: "/accounts/login",
                                                query: { next: router.asPath },
                                            }}
                                        >
                                            <a>Login</a>
                                        </Link>
                                    </li>
                                )}

                                {!isAuthenticated && (
                                    <li>
                                        <Link
                                            href={{
                                                pathname: "/accounts/register/",
                                                query: { next: router.asPath },
                                            }}
                                        >
                                            <a>Sign Up</a>
                                        </Link>
                                    </li>
                                )}

                                {isAuthenticated && (
                                    <li>
                                        <Link href="/plans/purchased-plans/">
                                            <a>My Plans</a>
                                        </Link>
                                    </li>
                                )}

                                {/* {isAuthenticated && ( */}
                                <li>
                                    <Link href="/plans/saved-plans/">
                                        <a>Saved Plans</a>
                                    </Link>
                                </li>
                                {/* )} */}

                                {/* {isAuthenticated && (
                                <li>
                                    <Link href="/projects/">
                                        <a>My Projects</a>
                                    </Link>
                                </li>
                            )} */}
                            </ul>
                        </div>
                    )}

                    <div className="col-6 col-sm-6 col-lg-2 mb-4 mb-lg-0">
                        <ul className="links-vertical text-white">
                            {showLinks(router.isReady) && (
                                <>
                                    <li className="nav-item active">
                                        <Link className="nav-link" href="/">
                                            <a>Home</a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link target="_blank" href="/blog">
                                            <a>Blog</a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link target="_blank" href="/faqs/">
                                            <a>FAQ</a>
                                        </Link>
                                    </li>
                                </>
                            )}
                            <>
                                <li>
                                    <Link href="/terms-and-conditions/">
                                        <a>Terms and Conditions</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/privacy-policy/">
                                        <a>Privacy Policy</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link target="_blank" href="/contact/">
                                        <a>Contact Us</a>
                                    </Link>
                                </li>
                            </>
                        </ul>
                    </div>

                    {showLinks(router.isReady) && (
                        <div className="col-6 col-sm-6 col-lg-2 mb-4 mb-lg-0">
                            <ul className="links-vertical text-white">
                                <li className="nav-item active">
                                    <Link className="nav-link" href="/nachu">
                                        <a>Inuua na NACHU</a>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
                <hr className="my-1 my-lg-3" />
                <div className="row">
                    <div className="col pb-4 mb-md-0">
                        {showLinks(router.isReady) && (
                            <div className="d-flex text-center justify-content-left align-items-center mb-3">
                                <ul className="list-inline">
                                    <li className="list-inline-item font-size-14 mr-4">
                                        <a
                                            target="_blank"
                                            rel="noopener"
                                            href="https://www.youtube.com/channel/UCIWMfsfjOJrXPPh5vckL0EA"
                                            className="footer-link-youtube"
                                        >
                                            <i className="fab fa-youtube text-muted"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item font-size-14 mr-4">
                                        <a
                                            target="_blank"
                                            rel="noopener"
                                            href="https://www.facebook.com/InuuaTujenge/"
                                            className="footer-link-facebook"
                                        >
                                            <i className="fab fa-facebook text-muted"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item font-size-14 mr-4">
                                        <a
                                            target="_blank"
                                            rel="noopener"
                                            href="https://twitter.com/inuuatujenge"
                                            className="footer-link-twitter"
                                        >
                                            <i className="fab fa-twitter text-muted"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item font-size-14 mr-4">
                                        <a
                                            target="_blank"
                                            rel="noopener"
                                            href="https://www.linkedin.com/company/inuuatujenge"
                                            className="footer-link-linkedin"
                                        >
                                            <i className="fab fa-linkedin text-muted"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item font-size-14 mr-4">
                                        <a
                                            target="_blank"
                                            rel="noopener"
                                            href="https://www.instagram.com/inuuatujenge/"
                                            className="footer-link-instagram"
                                        >
                                            <i className="fab fa-instagram text-muted"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        )}
                        <div className="d-flex text-center justify-content-left align-items-center">
                            <p className="font-weight-normal mb-0">
                                ©
                                <span className="current-year font-small">
                                    {" "}
                                    {year}
                                </span>
                                <span className="font-small"> Vyumba Ltd.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
