import {
    UPDATE_QUOTE_REQUEST_SUCCESS,
    UPDATE_QUOTE_REQUEST_FAILED,
    UPDATE_QUOTE_REQUEST_LOADING,
    UPDATE_QUOTE_REQUEST_STATUS_SUCCESS,
    UPDATE_QUOTE_REQUEST_STATUS_FAILED,
    UPDATE_QUOTE_REQUEST_STATUS_LOADING,
} from "@actions/admin/types";
import { Action } from "@src/interfaces/redux";

interface InitialState {
    updateQuoteRequestLoading: boolean;
    updateQuoteRequestFailed: boolean;
    updateQuoteRequestStatusLoading: boolean;
    updateQuoteRequestStatusFailed: boolean;
}
const initialState: InitialState = {
    updateQuoteRequestLoading: false,
    updateQuoteRequestFailed: false,
    updateQuoteRequestStatusLoading: false,
    updateQuoteRequestStatusFailed: false,
};

//

export default function dashboardReducer(
    state = initialState,
    action: Action
): InitialState {
    switch (action.type) {
        case UPDATE_QUOTE_REQUEST_STATUS_SUCCESS:
            return {
                ...state,
                updateQuoteRequestStatusLoading: false,
                updateQuoteRequestStatusFailed: false,
            };
        case UPDATE_QUOTE_REQUEST_STATUS_FAILED:
            return {
                ...state,
                updateQuoteRequestStatusLoading: false,
                updateQuoteRequestStatusFailed: true,
            };
        case UPDATE_QUOTE_REQUEST_STATUS_LOADING:
            return {
                ...state,
                updateQuoteRequestStatusLoading: true,
                updateQuoteRequestStatusFailed: false,
            };
        //
        case UPDATE_QUOTE_REQUEST_SUCCESS:
            return {
                ...state,
                updateQuoteRequestLoading: false,
                updateQuoteRequestFailed: false,
            };
        case UPDATE_QUOTE_REQUEST_FAILED:
            return {
                ...state,
                updateQuoteRequestLoading: false,
                updateQuoteRequestFailed: true,
            };
        case UPDATE_QUOTE_REQUEST_LOADING:
            return {
                ...state,
                updateQuoteRequestLoading: true,
                updateQuoteRequestFailed: false,
            };
        //
        default:
            return state;
    }
}
