export const GTM_ID =
    process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || "GTM-WCWXN65";

export function GTMPageView(url) {
    process.env.NODE_ENV === "development" && console.log("GTMPageView", url);
    const pageEvent = {
        event: "pageview",
        page: url,
    };

    window && window.dataLayer && window.dataLayer.push(pageEvent);
    return pageEvent;
}

export function resetValueGTM(key) {
    process.env.NODE_ENV === "development" && console.log("resetValueGTM", key);

    const eventData = {};
    eventData[key] = undefined;

    window && window.dataLayer && window.dataLayer.push(eventData);
    return eventData;
}

export function recordEventGTM(
    event,
    propertiesKey = "",
    propertiesValue = {}
) {
    process.env.NODE_ENV === "development" &&
        console.log("recordEvent", event, propertiesKey, propertiesValue);

    //
    const eventData = { event: event };
    if (!!propertiesKey && !!propertiesValue) {
        eventData[propertiesKey] = propertiesValue;
    }

    //
    window && window.dataLayer && window.dataLayer.push(eventData);
    return eventData;
}

export function recordVariableGTM(variable, value) {
    const variableData = {};
    variableData[variable] = value;
    //
    if (!!window && !!window.dataLayer) {
        window.dataLayer.push(variableData);
        process.env.NODE_ENV === "development" &&
            console.log(
                "GTM recordVariableGTM. window.dataLayer: ",
                window.dataLayer
            );
        return window.dataLayer;
    }
}

export function recordUserIdGTM(id) {
    return recordVariableGTM("userID", id);
}

// Google analytics
// export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

// // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
// export const pageview = (url) => {
//     window.gtag("config", GA_TRACKING_ID, {
//         page_path: url
//     });
// };

// // https://developers.google.com/analytics/devguides/collection/gtagjs/events
// export const event = ({ action, category, label, value }) => {
//     window.gtag("event", action, {
//         event_category: category,
//         event_label: label,
//         value: value
//     });
// };
