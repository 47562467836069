import {
    GET_BASKET_FAILED,
    GET_BASKET_LOADING,
    GET_BASKET_SUCCESS,
    CHECKOUT_FAILED,
    CHECKOUT_LOADING,
    CHECKOUT_SUCCESS,
    ADD_PLAN_PRODUCT_TO_BASKET_SUCCESS,
    ADD_PLAN_PRODUCT_TO_BASKET_FAILED,
    ADD_PLAN_PRODUCT_TO_BASKET_LOADING,
} from "../../actions/checkout/types";

const defaultBasket = {
    id: null,
    owner: null,
    status: null,
    lines: null,
    url: null,
    total_excl_tax: null,
    total_excl_tax_excl_discounts: null,
    total_incl_tax: null,
    total_incl_tax_excl_discounts: null,
    total_tax: null,
    currency: null,
    voucher_discounts: [],
    offer_discounts: [],
    is_tax_known: null,
};

const defaultCheckoutInfo = {
    number: null,
    basket: null,
    url: null,
    lines: null,
    owner: null,
    billing_address: null,
    currency: null,
    total_incl_tax: null,
    total_excl_tax: null,
    shipping_incl_tax: null,
    shipping_excl_tax: null,
    shipping_address: null,
    shipping_method: null,
    shipping_code: null,
    status: null,
    guest_email: null,
    date_placed: null,
    payment_url: null,
    offer_discounts: [],
    voucher_discounts: [],
};

const initialState = {
    getBasketLoading: false,
    getBasketFailed: false,
    basket: { ...defaultBasket },
    //
    addPlanProductToBasketLoading: false,
    addPlanProductToBasketFailed: false,
    //
    checkoutLoading: false,
    checkoutFailed: false,
    checkoutInfo: { ...defaultCheckoutInfo },
};

export default function plans(state = initialState, action) {
    switch (action.type) {
        case CHECKOUT_SUCCESS:
            return {
                ...state,
                checkoutInfo: action.payload,
                checkoutLoading: false,
                checkoutFailed: false,
                // reset
                basket: { ...defaultBasket },
            };
        case CHECKOUT_FAILED:
            return {
                ...state,
                checkoutLoading: false,
                checkoutFailed: true,
                checkoutInfo: { ...defaultCheckoutInfo },
            };
        case CHECKOUT_LOADING:
            return {
                ...state,
                checkoutLoading: true,
                checkoutFailed: false,
            };
        //
        case GET_BASKET_SUCCESS:
            return {
                ...state,
                basket: action.payload,
                getBasketLoading: false,
                getBasketFailed: false,
            };
        case GET_BASKET_FAILED:
            return {
                ...state,
                getBasketLoading: false,
                getBasketFailed: true,
                basket: { ...defaultBasket },
            };
        case GET_BASKET_LOADING:
            return {
                ...state,
                getBasketLoading: true,
                getBasketFailed: false,
            };
        //
        case ADD_PLAN_PRODUCT_TO_BASKET_SUCCESS:
            return {
                ...state,
                basket: action.payload,
                addPlanProductToBasketLoading: false,
                addPlanProductToBasketFailed: false,
            };
        case ADD_PLAN_PRODUCT_TO_BASKET_FAILED:
            return {
                ...state,
                addPlanProductToBasketLoading: false,
                addPlanProductToBasketFailed: true,
            };
        case ADD_PLAN_PRODUCT_TO_BASKET_LOADING:
            return {
                ...state,
                addPlanProductToBasketLoading: true,
                addPlanProductToBasketFailed: false,
            };
        //

        default:
            return state;
    }
}
