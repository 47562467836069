import axios from "../axiosConfig.js";
import { DEFAULT_API_URL, isNetworkError } from "../config";
import { createErrorMessage, createMessage, returnErrors } from "../messages";
import errorHandler from "../errorHandler";
import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGIN_LOADING,
    REGISTER_SUCCESS,
    REGISTER_FAILED,
    REGISTER_LOADING,
    REQUEST_RESET_PASSWORD_SUCCESS,
    REQUEST_RESET_PASSWORD_FAILED,
    REQUEST_RESET_PASSWORD_LOADING,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_LOADING,
    LOGOUT_SUCCESS,
    LOGOUT,
    CHECK_CACHED_AUTH,
} from "./types";

const defaultHeaders = {
    headers: {
        "Content-Type": "application/json",
    },
};

function afterAuth(dispatch) {}

//   <------------ LOGIN USER ------------>   //
export const login = (email, password) => (dispatch) => {
    dispatch({ type: LOGIN_LOADING });

    axios
        .post(
            `${DEFAULT_API_URL}/auth/token/login/`,
            JSON.stringify({ email: email, password: password }),
            defaultHeaders
        )
        .then((res) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            });
            afterAuth(dispatch);
        })
        .catch((err) => {
            errorHandler(dispatch, err, LOGIN_FAILED, "Logging in");
        });
};

//   <------------ REGISTER USER ------------>   //
export const registerUser = (
    email,
    password,
    phoneNumber,
    location,
    firstName,
    lastName
) => (dispatch) => {
    //
    dispatch({ type: REGISTER_LOADING });

    axios
        .post(
            `${DEFAULT_API_URL}/auth/users/`,
            {
                email: email,
                password: password,
                phone_number: phoneNumber,
                location: location,
                first_name: firstName,
                last_name: lastName,
                accepted_terms: true,
            },
            defaultHeaders
        )
        .then((res) => {
            dispatch(login(email, password));
            dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data,
            });
            dispatch(createMessage("Registration successful"));
        })
        .catch((err) => {
            errorHandler(dispatch, err, REGISTER_FAILED, "Registering user");
        });
};

//   <------------ RESET_PASSWORD USER ------------>   //
export const resetPassword = (new_password, uid, token) => (dispatch) => {
    //
    dispatch({ type: RESET_PASSWORD_LOADING });

    axios
        .post(
            `${DEFAULT_API_URL}/auth/users/reset_password_confirm/`,
            {
                new_password,
                uid,
                token,
            },
            defaultHeaders
        )
        .then(() => {
            dispatch({
                type: RESET_PASSWORD_SUCCESS,
            });
            dispatch(
                createMessage(
                    "Resetting password successful. Proceed to login."
                )
            );
        })
        .catch((err) => {
            errorHandler(
                dispatch,
                err,
                RESET_PASSWORD_FAILED,
                "Resetting password"
            );
        });
};

//   <------------ REQUEST_RESET_PASSWORD USER ------------>   //
export const requestResetPassword = (email) => (dispatch) => {
    //
    dispatch({ type: REQUEST_RESET_PASSWORD_LOADING });

    axios
        .post(
            `${DEFAULT_API_URL}/auth/users/reset_password/`,
            {
                email,
            },
            defaultHeaders
        )
        .then(() => {
            dispatch({
                type: REQUEST_RESET_PASSWORD_SUCCESS,
            });
            dispatch(
                createMessage(
                    "Requesting password reset successful. Please check your email for the next steps."
                )
            );
        })
        .catch((err) => {
            errorHandler(
                dispatch,
                err,
                REQUEST_RESET_PASSWORD_FAILED,
                "Requesting password reset"
            );
        });
};

// <------------ LOGOUT USER ------------> //
export const logout = () => (dispatch, getState) => {
    dispatch({
        type: LOGOUT,
    });
    axios
        .post(
            `${DEFAULT_API_URL}/auth/token/logout/`,
            {},
            headerHelper(getState)
        )
        .then(() => {
            dispatch({ type: "CLEAR_ALL" });
            dispatch({
                type: LOGOUT_SUCCESS,
            });
        })
        .catch((err) => {
            if (isNetworkError(err)) {
                dispatch(createErrorMessage("Network Error"));
            } else if (typeof err.response === "undefined") {
                return dispatch(createErrorMessage("Logout Failed"));
            } else {
                dispatch(
                    returnErrors(
                        err.response.data,
                        err.response.status,
                        "Logout Failed"
                    )
                );
            }
        });
};

// <------------ CHECK TOKEN & LOAD USER ------------> //
export const loadUser = () => (dispatch, getState) => {
    // no token
    if (!getState().authReducer.token) {
        dispatch({
            type: AUTH_ERROR,
        });
    }

    // User Loading
    dispatch({ type: USER_LOADING });
    axios
        .get(`${DEFAULT_API_URL}/auth/users/me/`, headerHelper(getState))
        .then((res) => {
            dispatch({
                type: USER_LOADED,
                payload: res.data,
            });
        })
        .catch(() => {
            dispatch({
                type: AUTH_ERROR,
            });
            dispatch({
                type: "AUTHENTICATION_ERROR",
            });
        });
};

export const checkCachedAuth = () => (dispatch, getState) => {
    const tkn = localStorage.getItem("token");
    dispatch({
        type: CHECK_CACHED_AUTH,
        payload: tkn,
    });
    //
    if (Boolean(tkn)) {
        afterAuth(dispatch);
    }
};

// <------------ USED TO ADD HEADERS TO REQUESTS ------------> //
export const headerHelper = (getState) => {
    // default headers
    const config = { headers: { "Content-Type": "application/json" } };

    // If we have a token, add to headers config
    const token = getState().authReducer.token; // Get token from state
    if (token) {
        config.headers.Authorization = `Token ${token}`;
    }

    return config;
};
