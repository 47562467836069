import React from "react";

import { Alert } from "react-bootstrap";

// the style contains only the margin given as offset
// options contains all alert given options
// message is the alert message
// close is a function that closes the alert
const AlertTemplate = ({ style, options, message, close }) => {
    //
    return (
        <>
            <div style={style}>
                <Alert
                    variant={options.type} // 'success', 'danger', 'info'
                    onClose={close}
                >
                    {message}
                </Alert>
            </div>
        </>
    );
};

export default AlertTemplate;
