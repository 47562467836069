export const GET_BASKET_SUCCESS = "GET_BASKET_SUCCESS";
export const GET_BASKET_FAILED = "GET_BASKET_FAILED";
export const GET_BASKET_LOADING = "GET_BASKET_LOADING";
export const ADD_PLAN_PRODUCT_TO_BASKET_SUCCESS =
    "ADD_PLAN_PRODUCT_TO_BASKET_SUCCESS";
export const ADD_PLAN_PRODUCT_TO_BASKET_FAILED =
    "ADD_PLAN_PRODUCT_TO_BASKET_FAILED";
export const ADD_PLAN_PRODUCT_TO_BASKET_LOADING =
    "ADD_PLAN_PRODUCT_TO_BASKET_LOADING";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILED = "CHECKOUT_FAILED";
export const CHECKOUT_LOADING = "CHECKOUT_LOADING";
