import recordUser, {
    clearUser,
    recordUserId,
} from "../../../utils/analytics/recordUser";
import {
    USER_LOADING,
    USER_LOADED,
    AUTH_ERROR,
    //
    LOGIN_FAILED,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    //
    REGISTER_SUCCESS,
    REGISTER_FAILED,
    REGISTER_LOADING,
    //
    REQUEST_RESET_PASSWORD_SUCCESS,
    REQUEST_RESET_PASSWORD_FAILED,
    REQUEST_RESET_PASSWORD_LOADING,
    //
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_LOADING,
    //
    LOGOUT,
    LOGOUT_SUCCESS,
    //
    CHECK_CACHED_AUTH,
} from "../../actions/auth/types";

// Auth tokens
// const getToken = () => {
//   return localStorage.getItem('token');
// };

const setToken = (token) => {
    if (typeof window != "undefined") {
        return localStorage.setItem("token", token);
    }
};
const deleteToken = () => {
    if (typeof window != "undefined") {
        return localStorage.removeItem("token");
    }
};
const invalidateUser = () => {
    deleteToken();
    clearUser();
};

const initialState = {
    user: null,
    token: null,
    isAuthenticated: false,
    isLoading: false,
    //
    loginAttemptFailed: false,
    //
    registerUserLoading: false,
    registerUserFailed: false,
    registerUserSuccess: false,
    //
    requestResetPasswordLoading: false,
    requestResetPasswordFailed: false,
    requestResetPasswordSuccess: false,
    //
    resetPasswordLoading: false,
    resetPasswordFailed: false,
    resetPasswordSuccess: false,
    //
    getUserLoading: false,
};

export default function auth(state = initialState, action) {
    switch (action.type) {
        case LOGIN_LOADING:
            return {
                ...state,
                loginAttemptFailed: false,
                isLoading: true,
            };
        case LOGIN_SUCCESS:
            setToken(action.payload.auth_token);
            return {
                ...state,
                loginAttemptFailed: false,
                ...action.payload,
                isAuthenticated: true,
                isLoading: false,
                token: action.payload.auth_token,
            };
        case LOGIN_FAILED:
            invalidateUser();

            return {
                ...state,
                loginAttemptFailed: true,
                token: null,
                user: null,
                isAuthenticated: false,
                isLoading: false,
            };

        case RESET_PASSWORD_LOADING:
            return {
                ...state,
                resetPasswordLoading: true,
                resetPasswordFailed: false,
                resetPasswordSuccess: false,
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordLoading: false,
                resetPasswordFailed: false,
                resetPasswordSuccess: true,
            };
        case RESET_PASSWORD_FAILED:
            return {
                ...state,
                resetPasswordLoading: false,
                resetPasswordFailed: true,
                resetPasswordSuccess: false,
            };

        case REQUEST_RESET_PASSWORD_LOADING:
            return {
                ...state,
                requestResetPasswordLoading: true,
                requestResetPasswordFailed: false,
                requestResetPasswordSuccess: false,
            };
        case REQUEST_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                requestResetPasswordLoading: false,
                requestResetPasswordFailed: false,
                requestResetPasswordSuccess: true,
            };
        case REQUEST_RESET_PASSWORD_FAILED:
            return {
                ...state,
                requestResetPasswordLoading: false,
                requestResetPasswordFailed: true,
                requestResetPasswordSuccess: false,
            };
        case REGISTER_LOADING:
            return {
                ...state,
                registerUserLoading: true,
                registerUserFailed: false,
                registerUserSuccess: false,
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                registerUserLoading: false,
                registerUserFailed: false,
                registerUserSuccess: true,
            };
        case REGISTER_FAILED:
            invalidateUser();
            return {
                ...state,
                token: null,
                user: null,
                isAuthenticated: false,
                isLoading: false,
                registerUserLoading: false,
                registerUserFailed: true,
                registerUserSuccess: false,
            };

        case USER_LOADING:
            return {
                ...state,
                loginAttemptFailed: false,
                getUserLoading: true,
                isLoading: true,
            };
        case USER_LOADED:
            recordUser(
                action.payload.id,
                action.payload.email,
                action.payload.phone_number,
                action.payload.first_name,
                action.payload.last_name,
                action.payload.location
            );
            return {
                ...state,
                loginAttemptFailed: false,
                isAuthenticated: true,
                getUserLoading: false,
                user: action.payload,
                isLoading: false,
            };

        case CHECK_CACHED_AUTH:
            // eslint-disable-next-line no-extra-boolean-cast
            if (Boolean(action.payload)) {
                return {
                    ...state,
                    isAuthenticated: true,
                    isLoading: false,
                    token: action.payload,
                    user: null,
                };
            } else {
                return {
                    ...state,
                    loginAttemptFailed: false,
                    token: null,
                    user: null,
                    isAuthenticated: false,
                    isLoading: false,
                };
            }

        case LOGOUT: {
            return {
                ...state,
                loginAttemptFailed: false,
                getUserLoading: false,
                isLoading: false,
            };
        }

        // TODO: handle session expired error from
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
        case "AUTHENTICATION_ERROR":
            invalidateUser();
            return {
                ...state,
                loginAttemptFailed: false,
                token: null,
                user: null,
                isAuthenticated: false,
                isLoading: false,
            };

        default:
            return state;
    }
}
