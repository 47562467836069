import React from "react";
import { DefaultSeo } from "next-seo";
import { defaultTitle, defaultDescription, defaultImages } from "./seoConfig";

// https://github.com/garmeeh/next-seo#default-seo-configuration
// default SEO properties that will appear on all pages without needing to do include anything on them. You can also override these on a page by page basis if needed.
export default function DefaultSEO() {
    return (
        <>
            <DefaultSeo
                defaultTitle={defaultTitle}
                titleTemplate={"%s | Inuua Tujenge"}
                description={defaultDescription}
                openGraph={{
                    type: "website",
                    locale: "en_GB",
                    url: "https://www.inuua.net/",
                    site_name: "Inuua Tujenge",
                    title: defaultTitle,
                    description: defaultDescription,
                    images: defaultImages,
                }}
                twitter={{
                    handle: "@inuuatujenge",
                    site: "@inuuatujenge",
                    cardType: "summary_large_image",
                }}
                facebook={{
                    appId: "191953729480599",
                }}
            />
        </>
    );
}
