import recordError from "../../utils/recordError";
import { isNetworkError } from "./config";
import { createErrorMessage, returnErrors } from "./messages";

/**
 * Axios network request error handler
 * @param {Object} dispatch - Redux dispatch
 * @param {Object} err - error from axios
 * @param {String} ACTION_TYPE - Action to be dispatched
 * @param {String} caption - Text that will be put in the failed title ( '${caption} failed' ) Example: "Logging in"
 */
export default function errorHandler(
    dispatch,
    err,
    ACTION_TYPE = null,
    caption = "Request"
) {
    if (process.env.NODE_ENV === "development") {
        console.log(err);
        console.log(err.response);
    }

    // if network error
    if (isNetworkError(err)) {
        dispatch(createErrorMessage("Network Error"));
    }

    // if undefined error
    else if (typeof err.response === "undefined") {
        dispatch(createErrorMessage(`${caption} failed`));
        recordError(err);
    }

    // authentication errors
    else if (err.response.status === 401) {
        // auth
        dispatch(createErrorMessage("Authentication Error"));
        dispatch({
            type: "AUTHENTICATION_ERROR",
        });
    }

    // other errors
    else {
        dispatch(
            returnErrors(
                err.response.data,
                err.response.status,
                `${caption} failed`
            )
        );
        recordError(err);
    }

    !!ACTION_TYPE &&
        dispatch({
            type: ACTION_TYPE,
        });
}
