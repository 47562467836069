import API_URL from "../../utils/apiUrl";

export const DEFAULT_API_URL = API_URL;

// check for network error
export function isNetworkError(err) {
    if (!!err.isAxiosError && !err.response) {
        return true;
    }
    return false;
}
